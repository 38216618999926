<template>
  <div class="form-row">
    <DetailOrderItemInputItem
      ref="dropdownInventory"
      placeholder="สินค้า..."
      class="col-5 col-md-5"
      select="id"
      :docType="docType"
      :templateType="templateType"
      :contactId="contactId"
      @change="recentPurchase"
      @keyup.enter.native="$refs.qty.setFocus()"
      v-model="formData.docConfigId"
      :validations="[
        {text: 'required!', value: $v.formData.docConfigId.$dirty && $v.formData.docConfigId.$error}
      ]">
    </DetailOrderItemInputItem>

    <DetailOrderItemInputUnit
      class="col-2 col-md-2"
      select="id"
      v-model="formData.unitId"
      :options="units"
      :validations="[
        {text: 'required!', value: $v.formData.unitId.$dirty && $v.formData.unitId.$error}
      ]">
    </DetailOrderItemInputUnit>

    <sgv-input-text
      ref="qty"
      class="col"
      placeholder="จำนวน ราคา [ส่วนลด | 3%]"
      v-model="formData.price"
      :validations="[
        {text: 'required!', value: $v.formData.price.$dirty && $v.formData.price.$error}
      ]"
      @keyup.enter.native="createOrder">
    </sgv-input-text>

    <div class="col-auto">
      <button class="btn btn-success" @click="createOrder">
        <fa icon="plus"></fa>
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailOrderItemInputItem from './DetailOrderItemInputItem.vue'
import DetailOrderItemInputUnit from './DetailOrderItemInputUnit.vue'
import {
  CREATE_ORDER_ITEM,
  DETAIL_ORDER_RECENT
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      formType: 'item',
      formData: {
        docConfigId: null,
        unitId: null,
        price: ''
      },
      units: []
    }
  },
  validations: {
    formData: {
      docConfigId: { required },
      unitId: { required },
      price: { required },
    }
  },
  methods: {
    serializeInput (input) {
      try {
        const obj = {
          docConfigId: input.docConfigId,
          unitId: input.unitId
        }
        const arr = input.price.split(' ')
        if (arr.length === 1) {
          obj.qty = 1
          obj.price = Number(arr[0])
          obj.discount = ''
        } else if (arr.length === 2) {
          obj.qty = Number(arr[0])
          obj.price = Number(arr[1])
          obj.discount = ''
        } else if (arr.length === 3) {
          obj.qty = Number(arr[0])
          obj.price = Number(arr[1])
          obj.discount = arr[2]
        }
        return obj
      } catch (err) {
        this.$toasted.global.error('กรอกข้อมูลไม่ถูกต้อง')
      }
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_ORDER_ITEM(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(() => {
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$refs.dropdownInventory.setFocus()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    setDefault () {
      this.formData.docConfigId = null
      this.formData.unitId = null
      this.formData.price = ''
      this.$v.formData.$reset()
    },
    recentPurchase (config) {
      if (!config) return

      const properties = config.inventory?.properties?.map(v => v.unit) || []
      this.units = [
        {id: config.inventory.unit.id, name: config.inventory.unit.name},
        ...properties
      ]

      this.$apollo.query({
        query: DETAIL_ORDER_RECENT(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          inventoryId: config.inventoryId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        const {qty, price, discount, unitId} = res.data.item
        this.formData.price = `${qty} ${price} ${discount || ''}`
        this.formData.unitId = unitId
      })
      .catch(() => {
        this.formData.unitId = config.inventory.unitId
      })
    }
  },
  watch: {
    'formData.docConfigId': {
      handler (v) {
        if (!v) {
          this.formData.price = ''
          this.formData.unitId = null
          this.units = []
          this.$v.formData.$reset()
        }
      }
    }
  },
  created () {
    this.setDefault()
  },
  components: {
    DetailOrderItemInputItem,
    DetailOrderItemInputUnit
  }
}
</script>

<style lang="css" scoped>
</style>
