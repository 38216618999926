<template>
  <div class="row mt-3">
    <template v-if="isAddressValid">
      <div class="col-12">
        <div class="form-row">
          <sgv-input-radio
            class="col-12"
            inline
            :options="types"
            select="value"
            v-model="selected">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-radio>
        </div>
      </div>

      <component
        class="col-12"
        :is="selected"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :formData="formData"
        addressType="sales"
        :url="`/doc/${docType}/${docId}/print/purchase`"
        :filename="`${formData.code}.pdf`">
      </component>
    </template>

    <template v-else>
      <div class="col-12">
        <span>ไม่พบค่าที่อยู่เริ่มต้น...</span>
      </div>
    </template>
  </div>
</template>

<script>
import DetailPrintPdf from '@/views/doc_core/components/PrintPdf'
import DetailPrintEmail from '@/views/doc_core/components/PrintEmail'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      types: [
        {text: 'Sample', value: 'DetailPrintPdf'},
        {text: 'Email', value: 'DetailPrintEmail'},
      ],
       selected: 'DetailPrintPdf'
    }
  },
  computed: {
    isAddressValid () {
      return this.formData.contact?.addresses.find(v => {
        return v.type === 'main' && v.isDefault
      })
    }
  },
  components: {
    DetailPrintPdf,
    DetailPrintEmail,
  }
}
</script>

<style lang="css" scoped>
</style>
