<template>
  <sgv-table
    :items="orderMapped"
    :headers="headers"
    :filter.sync="filter"
    :toolbars="toolbars"
    :options.sync="options">

    <template slot="option" v-if="options.toolbar === 'itemData'">
      <DetailOrderItemInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId">
      </DetailOrderItemInput>

      <DetailOrderServiceInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId">
      </DetailOrderServiceInput>

      <DetailOrderExpenseInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        :configs="configs">
      </DetailOrderExpenseInput>

      <DetailOrderDiscountPriceInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        :configs="configs">
      </DetailOrderDiscountPriceInput>

      <DetailOrderVatPriceInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        :configs="configs"
        :orders="orders">
      </DetailOrderVatPriceInput>

      <DetailOrderNetPriceInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        :configs="configs"
        :orders="orders">
      </DetailOrderNetPriceInput>
    </template>

    <template slot-scope="{item}">
      <DetailOrderRow
        :isVoid="formData.isVoid"
        :isDestroyable="!formData.approvedAt"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :item="item">
      </DetailOrderRow>
    </template>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import DetailOrderItemInput from './DetailOrderItemInput.vue'
import DetailOrderServiceInput from './DetailOrderServiceInput.vue'
import DetailOrderExpenseInput from './DetailOrderExpenseInput.vue'
import DetailOrderDiscountPriceInput from './DetailOrderDiscountPriceInput.vue'
import DetailOrderVatPriceInput from './DetailOrderVatPriceInput.vue'
import DetailOrderNetPriceInput from './DetailOrderNetPriceInput.vue'
import DetailOrderRow from './DetailOrderRow.vue'
import {
  LIST_ORDER,
  LIST_ORDER_CONFIG,
  WATCH_ORDER_CREATED,
  WATCH_ORDER_UPDATED,
  WATCH_ORDER_DESTROYED
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}DetailOrder`,
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'name', 'qty', 'unit', 'price', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      configs: [],
      orders: []
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      fetchPolicy: 'network-only',
      skip () {
        return !(this.docId > 0)
      },
      subscribeToMore: [
        {
          document () {
            return WATCH_ORDER_CREATED(this.templateType)
          },
          variables () {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const newOrder = subscriptionData.data.orderCreated
            const orders = [
              ...previous.orders,
              newOrder
            ]

            return {...previous, orders}
          }
        },
        {
          document () {
            return WATCH_ORDER_UPDATED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const updatedOrder = subscriptionData.data.orderUpdated
            const orders = [...previous.orders]

            const idx = orders.findIndex(v => v.id === updatedOrder.id)

            if (idx !== -1) {
              orders[idx] = updatedOrder
            }

            return {...previous, orders}
          }
        },
        {
          document () {
            return WATCH_ORDER_DESTROYED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const destroyedOrder = subscriptionData.data.orderDestroyed
            const orders = [...previous.orders]
            const idx = orders.findIndex(v => v.id === destroyedOrder.id)

            if (idx !== -1) orders.splice(idx, 1)

            return {...previous, orders}
          }
        }
      ]
    },
    configs: {
      query () {
        return LIST_ORDER_CONFIG(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          contactId: this.formData.contactId
        }
      },
      fetchPolicy: 'network-only'
    },
  },
  computed: {
    headers () {
      return [
        {text: 'ID', value: 'id'},
        {text: 'รายละเอียด', value: 'name'},
        {text: 'จำนวน', value: 'qty'},
        {text: 'ราคา/หน่วย', value: 'price'},
        {text: `ราคา`, value: 'totalPrice'}
      ]
    },
    toolbars () {
      let arr = []

      if (!this.formData.approvedAt && this.docId > 0) {
        arr.push({value: 'itemData', icon: 'plus', class: 'text-success'})
      }
      return arr
    },
    isEditable () {
      return this.formData.approvedAt && !this.formData.closedAt
    },
    orderMapped () {
      return [
        ...this.orders.filter(v => v.type === 'item'),
        ...this.orders.filter(v => v.type === 'service'),
        ...this.orders.filter(v => v.type === 'expense'),
        ...this.orders.filter(v => v.type === 'discountPrice'),
        ...this.orders.filter(v => v.type === 'vatPrice'),
        ...this.orders.filter(v => v.type === 'netPrice'),
      ]
    }
  },
  watch: {
    'formData.approvedAt': {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.options.toolbar = ''
        }
      }
    }
  },
  components: {
    DetailOrderItemInput,
    DetailOrderServiceInput,
    DetailOrderExpenseInput,
    DetailOrderDiscountPriceInput,
    DetailOrderVatPriceInput,
    DetailOrderNetPriceInput,
    DetailOrderRow
  }
}
</script>

<style lang="css">
</style>
